













































import Vue from "vue";
import Timer from "@/components/Timer.vue";
import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  data() {
    return {
      mission: "",
      sentences: this.$store.state.prioritized,
    };
  },
  components: {
    Timer,
    Footer,
  },

  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("ResetPrimarySelections"); 
  },
  // beforeMount: function () {
  // },
  methods: {
    ToggleSentence: function (index: number) {
      if (!this.$store.state.prioritized[index].primaryValue.selected) {
        this.$store.dispatch("SelectSentence", { index: index });
      } else {
        this.$store.dispatch("DeselectSentence", { index: index });
      }
    },
    UpdateMission: function () {
      this.$store.dispatch("UpdateMission", { mission: this.mission });
    },
  },
});
